.component {
  position: sticky;
  pointer-events: none;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-12);

  @media (--viewport-md) {
    padding: 0 var(--size-24);
  }
}

.componentLogo {
  padding: var(--size-12) 0;
  color: var(--color-white);
  transition: transform var(--duration-400) var(--ease);
  pointer-events: auto;

  &.isScrolledDown {
    transform: translateY(-100%);
  }
}

.componentMenu {
  display: flex;
  align-items: center;
  gap: var(--size-20);
  padding: var(--size-4);
  border-radius: var(--radius-20);
  background-color: var(--color-grey-900);
  pointer-events: auto;

  @media (--viewport-sm) {
    gap: var(--size-24);
  }

  &.hasMenu {
    padding-left: var(--size-20);

    @media (--viewport-sm) {
      padding-left: var(--size-24);
    }
  }
}

.componentDropdownButtonDesktop {
  display: flex;
  align-items: center;
  gap: var(--size-12);
  color: var(--color-white);

  @media not (--viewport-sm) {
    display: none;
  }

  & > .iconContainer {
    width: var(--size-12);
  }
}

.componentDropdownButtonMobile {
  --menu-text-color: var(--color-white);

  @media (--viewport-sm) {
    display: none;
  }

  & > .hamburgerLayout {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.mobileLabel {
  display: block;
  padding: var(--size-8) var(--size-24) var(--size-8) var(--size-12);
  font-family: var(--font-family-condensed);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-400);
  text-transform: uppercase;
  text-decoration: underline;
}

.submenuButtonLabel {
  --label-underline-opacity: 0;

  display: flex;
  align-items: center;
  font-family: var(--font-family-condensed);
  font-size: var(--size-20);
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    --label-underline-opacity: 1;
  }
}

.floatingContainer,
.submenuItemAnchor {
  outline: 0;
}

.componentSubmenuItem {
  display: flex;
  padding: var(--size-4) var(--size-12);
  border-radius: var(--radius-15);
  outline: 0;
  transition: background-color var(--duration-250) var(--ease);

  &:focus-within {
    background-color: var(--color-white--10);
  }

  & > .submenuItemAnchor {
    flex: 1;
    width: max-content;
  }
}

.submenuItemAnchor {
  padding-right: var(--size-12);
}

.submenuContainer {
  color: var(--color-white);
  background-color: var(--color-grey-900);
  border-radius: var(--radius-20);
  padding: var(--size-12);
  outline: 0;

  & > .submenuItemLayout {
    width: 100%;
  }
}

.iconContainer {
  transition: transform var(--duration-400) var(--ease-out-expo);

  &.isOpen {
    transform: rotateX(180deg);
  }

  & > .iconLayout {
    width: 100%;
  }
}
