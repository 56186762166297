._rootPage {
  min-height: 100dvh;
}

.component {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--theme-background-color);

  & > .skipLinkLayout {
    z-index: 2;
    position: fixed;
    width: max-content;
    margin: var(--size-12);
  }

  & > .headerLayout {
    z-index: 1;
    width: 100%;
    height: 0;
    top: 0;
  }

  & > .mainLayout {
    flex-grow: 1;
  }
}
